import React from 'react'
import { Helmet } from 'react-helmet'

const NotFound = ({...props}) => {
    return ( <>
        <div>
          <Helmet title={"Title"} />
          <div className="wrapper">
            <h1>Leider konnte die aufgerufene Seite nicht gefunden werden.</h1>
          </div>
        </div>
      </> );
}
 
export default NotFound;